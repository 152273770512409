import React, {Fragment} from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import photo from '../images/photo.png'
import favicon from '../images/favicon.ico'
import { Link, Grid, makeStyles } from '@material-ui/core';
import {Email} from '@material-ui/icons'
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  photo: {
    borderRadius:"100%",
    overflow: 'hidden',
    width: 200,
    height: 200,
    maxWidth: '60vw',
    maxHeight: '60vw',
    boxShadow: " 0px 0px 10px 4px #FFFFFF55",
    transition: '0.2s all ease-in-out',

    "&:hover": {
      filter: 'contrast(1.2);',
      transform: 'scale(1.05)',
      transition: '0.2s all ease-in-out'
    },
  },
})



export default function Index(props) {

  const classes = useStyles()
  
  return (
    <Fragment>
      <Helmet>
        <title>Agustín Caire</title>
        <link rel="shortcut icon" type="image/jpg" href={favicon}/>

      </Helmet>
    <Container maxWidth="sm">
      <Box my={4}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>

          <Typography variant="h3" component="h1">Agustín Caire</Typography>    

        </Grid>
        <Grid item>
          
          <Box className={classes.photo}>
            <img width="100%" height="100%" src={photo}/>
          </Box>

        </Grid>
        <Grid item>
          
          <Typography variant="h5" align="center">Sobre Mí</Typography>
          <Typography align="justify" gutterBottom>
          Soy un desarrollador de software especializado en backend al que le gusta resolver problemas y crear soluciones innovadoras y eficientes utilizando tecnología de vanguardia
          </Typography>
          <Typography align="justify" gutterBottom>
          Soy curioso por naturaleza, me gusta el dinamismo y enfrentarme a situaciones que desafíen mis conocimientos.
          </Typography>
          <Typography align="justify" gutterBottom>
          Contantemente me encuentro aprendiendo nuevas tecnologías, lenguajes y frameworks lo cuál me permite mejorar mis habilidades y obtener nuevas en otras áreas como desarrollo frontend y devOp.
          </Typography>

        </Grid>
        <Grid item>
          
          <Typography variant="h5" align="center">Tecnologías y herramientas:</Typography>
          <ul>
            <li>Node.Js</li>
            <li>Python</li>
            <li>MySQL / PostgreSQL</li>
            <li>MongoDB</li>
            <li>HTML / CSS / Javascript / Typescript</li>
            <li>AWS</li>
            <li>Firebase</li>
            <li>Docker</li>
            <li>Kubernetes</li>
          </ul>
          <Typography align="center">Frameworks</Typography>
          <ul>
            <li>Vue</li>
            <li>React</li>
            <li>Express</li>
            <li>Fastify</li>
          </ul>

        </Grid>
        <Grid item>
          
          <Typography variant="h5" align="center">Experiencia</Typography>
          <Typography align="justify" gutterBottom>
            <b>Backend Developer en Asimov S.R.L (2019-01 - actualidad): </b> Realizando tareas de diseño y desarrollo de APIs para diferentes proyectos incluyendo características como e-commerce, turnos web y otros scripts específicos. 
            También me he encargado de realizar el deploy de las aplicaciones utilizando servicios de cloud. 
          </Typography>

        </Grid>
        <Grid item>
          
          <Typography variant="h5" align="center">Proyectos</Typography>
          <Typography align="justify" gutterBottom>
            <Link href="https://marketstats.agustincaire.xyz" target="_blank" color="primary">Market Stats:</Link> Es una web app que permite acceder a estadísticas de rendimiento histórico de empresas en la bolsa. Puedes encontrar mas información ingresando al 
            &nbsp;<Link href="https://marketstats.agustincaire.xyz" target="_blank" color="primary">sitio</Link>
          </Typography>

        </Grid>
        <Grid item>
          
          <Typography variant="h5" align="center">Contacto:</Typography>
          <Grid container>
            <Grid item><Email/></Grid>
            <Grid item>&nbsp;agustincaire@gmail.com</Grid>
          </Grid>


        </Grid>

      </Grid>
        
      </Box>
    </Container>
    </Fragment>
  );
}